<template>
  <div>
    <a-modal width="600px" title="编辑学时" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk"
      @cancel="cancel">
      <a-form ref="formRef" :model="formState" :rules="rules">
        <a-form-item label="规定时长：">
          <a-input-group direction="vertical">
            <a-row :gutter="8">
              <a-col :span="5">
                <a-input-number v-model:value="formState.hour" precision="0" placeholder="时" :min="0" size="small" />
              </a-col>时
              <a-col :span="5">
                <a-select v-model:value="formState.minute" placeholder="分" size="small" :options="options"></a-select>
              </a-col>分
              <a-col :span="5">
                <a-select v-model:value="formState.seconds" placeholder="秒" size="small" :options="options">
                </a-select>
              </a-col>秒
            </a-row>
          </a-input-group>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
  import { defineComponent } from "vue";
  import { CreditHourSettingController } from "@/api/stipulateTime";
  import { message } from 'ant-design-vue';
  const basicForm = {
    userIds: [],
    //时
    hour: null,
    //分钟
    minute: null,
    //秒
    seconds: null,
    requireDuration: null,
  };

  export default defineComponent({
    name: "Form",
    components: {},
    props: {
      edit: Boolean,
      typeOptions: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        formState: {
          ...basicForm,
        },
        confirmLoading: false,
        visible: false,
        rules: {
          requireDuration: [
            { required: true, message: "不能为空", trigger: "blur" },
          ],
        },
      };
    },
    created() { },
    computed: {
      options() {
        const array = []
        for (let i = 0; i < 60; i++) {
          array.push({ value: i, label: i, })
        }
        return array
      }
    },
    methods: {
      cancel() {
        this.$refs.formRef.resetFields();
        this.visible = false;
      },
      handleOk() {
        if (this.formState.hour!=null && this.formState.minute !=null && this.formState.seconds !=null) {
          this.formState.requireDuration = this.formState.hour
            + ':'
            + this.formState.minute
            + ':'
            + this.formState.seconds
          this.$refs.formRef.validate().then(() => {
            this.confirmLoading = true;
            CreditHourSettingController(this.formState)
              .then((res) => {
                this.$message.success("编辑成功");
                this.$emit('success')
                this.visible = false;
              })
              .finally(() => {
                this.confirmLoading = false;
              });
          });
        } else {
          message.error('请输入时分秒，并补充完整');
        }

      },
      showModal(requireDuration, userIds) {
        this.formState = {
          ...basicForm,
          requireDuration: requireDuration ? requireDuration : null,
          userIds,
        };
        if(requireDuration){
          this.formState.hour=requireDuration.split(':')[0]
          this.formState.minute=requireDuration.split(':')[1]
          this.formState.seconds=requireDuration.split(':')[2]
        }
        this.visible = true;
      },
    },
  });
</script>
<style lang="less">
  .page-wrapper {
    padding: 20px 32px;
  }

  .radio {
    width: 100px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border: 1px solid #e0e0e2;
  }
</style>