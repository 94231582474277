
  import {
    defineComponent,
    PropType,
    reactive,
    toRefs,
    ref,
    h,
    watch,
    onMounted,
  } from "vue";
  import popupNew from "./popupNew.vue";
  import pagaTable from "@/components/pagination-table/index";
  import { TransformCellTextProps } from "ant-design-vue/lib/table/interface";
  import formSearch from "@/components/page-search/form";
  import { departmentTree } from "@/api/system";
  import { CreditHourSettingControllerPage } from "@/api/stipulateTime";
  import { message } from "ant-design-vue";

  const purchaseColumns = [
    {
      title: "序号",
      width: 60,
      dataIndex: "index",
      customRender: (row: TransformCellTextProps) => row.index + 1,
    },
    {
      title: "部门",
      dataIndex: "department",
    },
    {
      title: "姓名",
      dataIndex: "realName",
    },
    {
      title: "身份证号",
      dataIndex: "identification",
    },
    {
      title: "规定培训有效学时",
      dataIndex: "requireDuration",
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 200,
      slots: { customRender: "operation" },
    },
  ];
  export default defineComponent({
    name: "questionType",
    components: {
      pagaTable,
      formSearch,
      popupNew,
    },
    setup(props: any) {
      const data = reactive({
        purchaseColumns,
        selectedRowKeys: [],
        extraModel: {},
        formBtn: {
          colProps: { span: 18 },
          formItemProps: {
            wrapperCol: {
              span: 24,
            },
          },
        },
        formConfig: [
          {
            colProps: {
              span: 6,
            },
            formItemProps: {
              label: "姓名：",
            },
            comConfig: {
              tag: "input", // input, select, date-picker
              key: "realName", // 即是插槽也是字段model key
              props: {
                placeholder: "请输入",
              },
            },
          },
        ],
      });
      const onSelectChange = (selectedRowKeys: never[]) => {
        data.selectedRowKeys = selectedRowKeys;
      };
      const table = ref();
      const params_api = ref();
      const getDataApi = (params: any) => {
        return new Promise((reslove) => {
          params_api.value = {
            currentPage: params.pageIndex,
            pageSize: params.pageSize,
            departmentId: departmentId.value,
            ...formSearch.value.getQuery(),
          };
          CreditHourSettingControllerPage({ ...params_api.value }).then(
            (res) => {
              reslove(res);
            }
          );
        });
      };
      const handleChange = (
        pagination: any,
        filter: any,
        sorter: any,
        { currentDataSource }: any
      ) => {
        console.log(pagination, filter, sorter, currentDataSource);
      };
      const handleClick = (text: string, row: any) => { };
      const formSearch = ref();
      const popupWindow = ref();
      const Title = ref();
      const updatedTime = ref([]);
      const handleSearch = (formState: any) => {
         data.selectedRowKeys=[] 
        table.value.refresh();
      };
      const selectedKeys = ref < string[] > ([]);
      const departmentId = ref();
      watch(selectedKeys, () => {
        departmentId.value = selectedKeys.value;
        handleSearch({});
      });
      const value = ref < string > ("");

      const treeData = ref([]);
      departmentTree({}).then((res) => {
        treeData.value = res.data;
      });
      const replaceFields = {
        children: "children",
        key: "departmentId",
        title: "departmentName",
      };
      const popupNew = ref();
      const popupNewClick = (isNo,value: any) => {
        if(isNo){
         popupNew.value.showModal(null, value);
        }else{
         popupNew.value.showModal(value.requireDuration, [value.userId]);
        }
      };      
      return {
        popupNewClick,
        popupNew,
        replaceFields,
        treeData,
        selectedKeys,
        ...toRefs(data),
        table,
        onSelectChange,
        getDataApi,
        handleChange,
        handleClick,
        formSearch,
        popupWindow,
        Title,
        handleSearch,
        updatedTime,
      };
    },
  });
